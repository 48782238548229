import React from 'react'
import styled from 'styled-components'
import { Helmet } from 'react-helmet'
import { graphql } from 'gatsby'
import {
  Layout,
  Grid,
  MerchProductGrid,
  MerchProductGridItem,
  ReviewsBanner,
  MerchLandingBanner,
} from '@components'
import { typeStyle } from '@theme'
// @ts-ignore
import marketBag from '@assets/dummyImages/market-bag.jpeg'


interface ProductProps {
  data: GatsbyTypes.MerchCollectionPageQuery
}


const MerchCollectionPage = ({data: {
  allShopifyProduct,
  otherSanityProducts,
  sanitySiteSettings,
  sanityMerchLandingPage
}}: ProductProps) => {

  

  if (
    !otherSanityProducts ||
    !allShopifyProduct ||
    !sanitySiteSettings ||
    !sanityMerchLandingPage
  )
    return <></>
  
  const {
    
    title,
    bannerImage,
    reviewsAndPix,
    mainColor,
    mainColorForeground,
    altColor,
    centerReviewImage
   
  } = sanityMerchLandingPage

 
  const findProductVariant = (handle?: string) => {
    const d = allShopifyProduct.nodes.find((p) => p.handle === handle)
    return d?.variants?.[0]
  }



  return (
    <Layout>
      <Helmet>
        <title>Bawi Agua Fresca: {title}</title>
      </Helmet>
      <Grid>
      <MerchLandingBanner title={title as string} image={bannerImage?.asset?.url} />
      
       
        <MerchProductGrid>
          {Array.from(otherSanityProducts.nodes).map(
            (otherProduct) => 
              !!otherProduct && (
                <MerchProductGridItem variant={findProductVariant(otherProduct.slug?.current) as GatsbyTypes.Maybe<GatsbyTypes.ShopifyProductVariant>} isLandingPage availableColors={otherProduct?.merchAvailableColorsSelector as string[]} product={otherProduct as Partial<GatsbyTypes.SanityProduct>} key={otherProduct._id}/>
              )
          )}
        </MerchProductGrid>
       
        <ShopOtherProducts>
          <Title>
           market bag included with all bawi merch purchases
          </Title>
          <MarketBagImage src={marketBag} />
        </ShopOtherProducts>  
        {reviewsAndPix && reviewsAndPix.length > 0 && (
          <ReviewsBanner
            {...{
              reviewsAndPix,
              mainColor,
              mainColorForeground,
              altColor,
              centerReviewImage,
            }}
          />
        )}
      </Grid>
    </Layout>
  )
}


export const query = graphql`
  query MerchCollectionPage {
    sanitySiteSettings {
      ...siteSettingsFields
    }
    sanityMerchLandingPage {
      _id
      title
      mainColor,
      mainColorForeground,
      altColor,
      centerReviewImage {
        asset {
          url
        }
      }
      bannerImage {
        asset {
          url
        }
      }
     
      reviewsAndPix {
        _key
        _type
        backgroundColor
        foregroundColor
        name
        stars
        review {
          _rawEnglish
          _rawSpanglish
          _rawSpanish
        }
        flipImage {
          asset {
            url
          }
        }
      }
      centerReviewImage {
        asset {
          url
        }
      }
    }
    otherSanityProducts: allSanityProduct(filter: { type: { eq: "accessory" } }) {
      nodes {
        _id
        _key
        slug {
          current
        }
        type
        title
        merchAvailableColorsSelector
        merchImageMain {
          asset {
            url
          }
        }
        merchGridCardImage {
          asset {
            url
          }
        }
       
        accentSticker 
        bannerImage {
            asset {
            url
            }
        }
        mainColor
        mainColorForeground
        altColor
        altColorForeground
        summary {
          _rawSpanish
          _rawSpanglish
          _rawEnglish
        }
      }
    }
    allShopifyProduct {
      nodes {
        title
        handle
        variants {
          price
          storefrontId
          inventoryQuantity
          title
        }
      }
    }
  }
`

const MarketBagImage = styled.img`
  height: 200px;
  width: 180px;
  border-radius: 10px;
  border: 2px solid black;
  flex-shrink: 0;

  @media only screen and (min-width: 744px) {
    height: 308px;
    width: 279px;
  }
 
`


const ShopOtherProducts = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center; 
  justify-content: center; 
  padding: 50px 0; 
  position: relative;
  background-color: white; /* Or any color */
  z-index: 1;
  @media only screen and (min-width: 744px) {
    grid-column-end: span 12;
   
    padding: 20px 80px;
  }
`;

const Title = styled.h1`
  ${typeStyle.eyebrow}
  color: black;
  text-align: center;
  margin-bottom: 20px;
  @media only screen and (min-width: 744px) {
   font-size: 52px;
  }
  
`
export default MerchCollectionPage
